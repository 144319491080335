import React from "react";
import { Router } from "@reach/router";

import { ClientOnly, ErrorMessage, ERROR_CODES } from "../../components";
import { ProtectedRoute, useMerchantAuth } from "../../core/auth";

import Login from "./login";
import LoginCallback from "./login/callback";
import Logout from "./logout";
import Home from "./home/index";
import Summary from "./orders/summary";
import Dashboard from "./orders/dashboard";
import CreateOrder from "./orders/create";
import Review from "./orders/review";
import Analytics from "./analytics/index";
import Graphs from "./statements/graphs"
import TrainingGuides from "./trainingguides/index";
import InstorePosSummary from "./pos/orders/summary";
import InstorePosInitiate from "./pos/initiate";
import Reports from "./reports";
import settlementsummary from "./statements/settlementsummary";
import SelectMerchant from "./selectmerchant/index";
import { AMAZON_MERCHANTS } from "../../layout/merchant";

const ErrorInvalidRequest = () => (
  <ErrorMessage type={ERROR_CODES.INVALID_MERCHANT_TOKEN} />
);

const Merchant = () => {
  const { profile } = useMerchantAuth();
   //temporary logic until when we are ready access for all merchants
  let statementDisabled
  if(AMAZON_MERCHANTS.includes(profile?.merchantId)){
    statementDisabled= false
  }else{
    statementDisabled= true
  }
  
  return (
    <ClientOnly>
      <Router basepath="/merchant">
        <ProtectedRoute
          exact
          path="/login"
          mode={ProtectedRoute.MODE.MERCHANT}
          as={Home}
          SignInComponent={Login}
        />

        <ProtectedRoute
          path="/saml"
          mode={ProtectedRoute.MODE.MERCHANT}
          as={Home}
          SignInComponent={Login}
          idpType="saml"
        />

        <ProtectedRoute
          exact
          path="/login/saml"
          mode={ProtectedRoute.MODE.MERCHANT}
          as={Home}
          SignInComponent={Login}
          idpType="saml"
        />

        <LoginCallback path="/login/callback" />

        <Logout path="/logout" />

        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/home"
          as={Home}
          SignInComponent={Login}
        />

        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/orders/dashboard"
          as={Dashboard}
          SignInComponent={Login}
          isDisabled={!Boolean(profile?.features?.isOrderReadEnabled)}
        />

        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/orders/create"
          as={CreateOrder}
          SignInComponent={Login}
        />
        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/selectmerchant"
          as={SelectMerchant}
          SignInComponent={Login}
        />

        <Summary path="/orders/summary" as={Summary} />

        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/orders/review"
          as={Review}
          SignInComponent={Login}
        />
        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/statements/settlementsummary"
          as={settlementsummary}
          SignInComponent={Login}
          isDisabled={Boolean(statementDisabled)} 
         
        />
        <ProtectedRoute  
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/statements/graphs"
          as={Graphs}
          SignInComponent={Login}
          isDisabled={Boolean(statementDisabled)} 
        />
        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/reports"
          as={Reports}
          SignInComponent={Login}
          isDisabled={!Boolean(profile?.features?.isStatementEnabled)} 
        />

        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/analytics"
          as={Analytics}
          SignInComponent={Login}
          isDisabled={!Boolean(profile?.features?.isAnalyticsEnabled)}
        />

        <ProtectedRoute
          mode={ProtectedRoute.MODE.MERCHANT}
          path="/trainingguides"
          as={TrainingGuides}
          SignInComponent={Login}
        />

        <ProtectedRoute
          path="/pos/orders/summary"
          mode={ProtectedRoute.MODE.DEFAULT}
          ErrorComponent={ErrorInvalidRequest}
          as={InstorePosSummary}
        />

        <ProtectedRoute
          path="/pos/initiate"
          mode={ProtectedRoute.MODE.DEFAULT}
          ErrorComponent={ErrorInvalidRequest}
          as={InstorePosInitiate}
        />
      </Router>
    </ClientOnly>
  );
};

export default Merchant;
